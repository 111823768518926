const PORTAL_ORIGIN = window.__ROOT_CONFIG_ENV__.PORTAL_ORIGIN;
const PORTAL_PATH = '/portal_';
const APOLLO_PATH = `${PORTAL_PATH}/apollo/packages`;
const getOrganization = () => {
    const firstPathSegment = window.location.pathname.split('/').filter(Boolean)[0];
    return (firstPathSegment === null || firstPathSegment === void 0 ? void 0 : firstPathSegment.endsWith('_')) ? undefined : firstPathSegment;
};
const createAndAppendElement = (tagName, attributes) => {
    const element = document.createElement(tagName);
    Object.entries(attributes).forEach(([key, value]) => element.setAttribute(key, value));
    document.head.appendChild(element);
};
const buildUrl = (path) => {
    const orgPath = getOrganization() ? `/${getOrganization()}` : '';
    return `${PORTAL_ORIGIN}${orgPath}${path}`;
};
const loadImportMapAndResources = () => {
    const resources = [
        { tagName: 'script', attributes: { type: 'importmap-shim', src: buildUrl(`${PORTAL_PATH}/api/importmap`) } },
        { tagName: 'link', attributes: { rel: 'stylesheet', href: buildUrl(`${APOLLO_PATH}/apollo-fonts/1/font.css?spaceName=portal`) } },
        { tagName: 'script', attributes: { type: 'module', src: buildUrl(`${APOLLO_PATH}/portal-shell/3/portal-shell.esm.js?spaceName=portal`) } }
    ];
    resources.forEach(({ tagName, attributes }) => createAndAppendElement(tagName, attributes));
};
loadImportMapAndResources();
